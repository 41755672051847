<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';
import { LOGOUT } from '@/store/actionTypes';

export default {
  created() {
    this.logout().then(() => {
      this.$router.push('/login');
    });
  },

  methods: {
    ...mapActions([LOGOUT]),
  },
};
</script>
